<template>
  <div class="main-order">
    <div
      class="left-grid"
      :class="{'rss-hide': !showRss}"
    >
      <div class="panel1">
        <BriefcaseHorizontal />
      </div>
      <div class="card indent panel2">
        <Referral />
      </div>
      <div class="card indent panel3">
        <TransactionsHistory />
      </div>
      <div class="card indent panel4">
        <Orders
          :items-per-page="4"
          :header-line="true"
          :is-pagination="false"
        />
      </div>
      <div class="card indent panel5">
        <Voting />
      </div>
      <div class="card panel6">
        <IncomeChart />
      </div>
      <div
        v-if="true"
        class="card indent panel7"
      >
        <News />
      </div>
      <div
        v-if="showRss"
        class="card indent panel8"
      >
        <Rss />
      </div>
    </div>
  </div>
</template>

<script>
import BriefcaseHorizontal from "@/panels/components/BriefcaseHorizontal";
import IncomeChart from "@/components/dashboard/IncomeChart";
import News from "@/components/dashboard/News";
import Orders from "@/panels/components/Orders";
import Referral from "@/components/dashboard/Referral";
import Rss from "@/components/dashboard/Rss";
import TransactionsHistory from "@/components/dashboard/TransactionsHistory";
import Voting from "@/components/dashboard/Voting";
import { mapGetters } from "vuex";
export default {
  name: "Dashboard2",
  components: { Rss, TransactionsHistory, IncomeChart, BriefcaseHorizontal, Orders, Voting, Referral, News },
  computed: {
    ...mapGetters({
      language: "language"
    }),

    isIssuer() {
      return this.$auth.user().type == "issuer";
    },

    showRss() {
      return this.language === "en" ? process.env.VUE_APP_RSS_EN_URL : process.env.VUE_APP_RSS_RU_URL;
    }
  },
  mounted() {
    if (this.isIssuer) {
      this.$router.push({ name: "issuer" });
    }
  }
};
</script>

<style scoped lang="scss">
.left-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, min-content) auto;
  grid-template-areas:  "p1 p2 p3"
                        "p4 p4 p6"
                        "p4 p4 p5"
                        "p7 p7 p5"
                        "p7 p7 p8";
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, auto);
    grid-template-areas:  "p1 p2"
                          "p3 p3"
                          "p5 p5"
                          "p4 p4"
                          "p6 p6"
                          "p7 p8";
  }
  @media screen  and (max-width: 900px){
    grid-template-columns: 1fr;
    grid-template-areas:  "p1"
                          "p2"
                          "p3"
                          "p5"
                          "p4"
                          "p6"
                          "p7"
                          "p8"
  }

}
.panel1 {
  grid-area: p1;
}
.panel2 {
  grid-area: p2;
}
.panel3 {
  grid-area: p3;
}
.panel4 {
  grid-area: p4;
}
.panel5 {
  grid-area: p5;
  padding: 15px 15px 0 15px;
}
.panel6 {
  grid-area: p6;
  padding: 15px;
  height: min-content;
}
.panel7 {
  grid-area: p7;
  height: min-content;
}
.panel8 {
  grid-area: p8;
  height: min-content;
}
.indent {
  padding: 15px;
}
.rss-hide {
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:  "p1 p2"
                          "p3 p3"
                          "p5 p5"
                          "p4 p4"
                          "p6 p6"
                          "p7 p7";
  }
}
</style>