<template>
  <div>
    <div class="ib-title">
      {{ name }}
    </div>
    <div class="ib-price">
      <div class="line">
        <img
          class="icon-up"
          src="/img/capital/price_tag.svg"
          alt="price"
        >
        <span>{{ $t("auCapitals.investBlock.price") }} &ndash;</span>
        <AuDecimalIcon
          :value="priceBase"
          :img-src="tokenIcon"
          :decimal="mnemonicDecimals"
          :indent="mnemonicIndent"
          :tooltip-text="mnemonicEng"
        />
      </div>
      <div class="line">
        <img
          class="icon-up"
          src="/img/capital/profit.svg"
          alt="Minimum invested size"
        >
        <span>{{ $t("auCapitals.investBlock.minQuote") }} &ndash;</span>
        <span v-if="minQuote && minQuote != 0">
          <AuDecimalIcon
            :value="minQuote"
            :img-src="tokenIcon"
            decimal="0"
            indent="0"
            :tooltip-text="mnemonicEng"
          />
        </span>
        <span v-else>
          <AuDecimalIcon
            :value="priceBase"
            :img-src="tokenIcon"
            :decimal="mnemonicDecimals"
            :indent="mnemonicIndent"
            :tooltip-text="mnemonicEng"
          />
        </span>
      </div>
      <div class="line">
        <div class="d-flex gap-1 align-items-center">
          <img
            class="blockchain-img"
            :src="logoIcon"
            alt="logo"
          >
          <span><a
            :href="scannerTokenLink"
            target="_blank"
          >{{ tokenBase.mnemonicEng }}</a></span>
        </div>
      </div>
    </div>
    <div class="ib-info">
      <div
        v-if="minExpertVotes > 0"
        class="record"
      >
        <span>{{ $t("auCapitals.investBlock.expertVotes") }}</span>
        <div class="sep" />
        <span>{{ expertVotes }}&nbsp;&#47;&nbsp;{{ minExpertVotes }}</span>
      </div>
      <div class="record">
        <span>{{ $t("auCapitals.investBlock.contractAdr") }}</span>
        <div class="sep" />
        <span
          v-if="smartContract"
          class="green link"
          @click="goToAdrScanner"
        >{{ shortAddressLowercase(smartContract) }}</span>
        <span
          v-else
          class="grey"
        >{{ $t("auCapitals.investBlock.none") }}</span>
      </div>
      <div class="record">
        <span>{{ $t("auCapitals.investBlock.tokenAdr") }}</span>
        <div class="sep" />
        <span
          v-if="createdAddress"
          class="green link"
          @click="goToTokenScanner"
        >{{ shortAddressLowercase(createdAddress) }}</span>
        <span
          v-else
          class="grey"
        >{{ $t("auCapitals.investBlock.none") }}</span>
      </div>
      <div class="record">
        <span>{{ $t("auCapitals.investBlock.tokenType") }}</span>
        <div class="sep" />
        <span>{{ assetType }}</span>
      </div>
      <div class="record">
        <div>
          {{ $t("auCapitals.investBlock.expectedIncome") }}
        </div>
        <div class="sep" />
        {{ profitability }}%
      </div>
      <div class="record">
        <span class="green">{{ timeText }}</span>
        <div class="sep sep-green" />
        <span class="green">{{ timeDate }}</span>
      </div>
    </div>
    <div class="ib-progress">
      <div
        :style="infoStyle"
        class="under-info"
      >
        <div
          v-if="minCap"
          :style="softStyle"
          class="info-wrapper"
        >
          <span class="label">
            {{ $t("auCapitals.projectCard.softCap") }}
          </span>
          <span class="value">
            <AuDecimalStatic
              :value="minCap"
              :addition="mnemonicEng"
              :decimal="mnemonicDecimals"
              :indent="mnemonicIndent"
            />
          </span>
        </div>

        <div
          class="info-wrapper"
          :class="{'hard': !minCap}"
        >
          <span class="label">
            {{ $t(`auCapitals.projectCard.hardCap${vote}`) }}
          </span>
          <span class="value">
            <AuDecimalStatic
              :value="maxCap"
              :addition="mnemonicEng"
              :decimal="mnemonicDecimals"
              :indent="mnemonicIndent"
            />
          </span>
        </div>
      </div>
      <ProgressBar
        class="project-progressbar"
        :procent="progressProcent"
        :status="status"
      />
      <div class="above-info">
        <div
          :style="amountStyle"
          class="info-wrapper"
        >
          <span class="label">
            {{ $t(`auCapitals.projectCard.amountRaised${vote}`) }}
          </span>
          <span class="value">
            <AuDecimalStatic
              :value="currentCap"
              :addition="mnemonicEng"
              :decimal="mnemonicDecimals"
              :indent="mnemonicIndent"
            />
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="showInvestCountBlock"
      class="ib-popular"
    >
      <AuButton
        center
        padding-horizontal="0"
        @click="investCount=investValue[0]"
      >
        <AuDecimalIcon
          :value="investValue[0]"
          :img-src="tokenIcon"
          decimal="0"
          indent="0"
        />
      </AuButton>
      <AuButton
        center
        padding-horizontal="0"
        @click="investCount=investValue[1]"
      >
        <AuDecimalIcon
          :value="investValue[1]"
          :img-src="tokenIcon"
          decimal="0"
          indent="0"
        />
      </AuButton>
      <AuButton
        center
        padding-horizontal="0"
        @click="investCount=investValue[2]"
      >
        <AuDecimalIcon
          :value="investValue[2]"
          :img-src="tokenIcon"
          decimal="0"
          indent="0"
        />
      </AuButton>
    </div>
    <AuInput
      v-if="showInvestCountBlock"
      v-model="investCount"
      :placeholder="$t('auCapitals.investBlock.investValuePlaceholder')"
    />
    <AuButton
      :class="{'mt-1': !showInvestCountBlock, 'mt-4': showInvestCountBlock}"
      type="primary"
      height="61px"
      width="100%"
      center
      :disabled="status !== 'Open' || !isAllowed || progressProcent >= 100"
      @click="$emit('invest', investCount)"
    >
      <div class="ib-invest">
        <img
          :src="tokenIcon"
          alt="token"
        >
        <span>{{ $t(`auCapitals.investBlock.buttonTxt${vote}`) }}</span>
      </div>
    </AuButton>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";
import ProgressBar from "@/components/progressBar/ProgressBar";
import { mapGetters } from "vuex";

export default {
  name: "InvestBlock",
  components: { ProgressBar },
  props: {
    name: {
      type: String,
      default: ""
    },
    priceBase: {
      type: String,
      default: ""
    },
    mnemonicDecimals: {
      type: String,
      default: ""
    },
    mnemonicIndent: {
      type: String,
      default: ""
    },
    mnemonicEng: {
      type: String,
      default: ""
    },
    minCount: {
      type: String,
      default: ""
    },
    minCap: {
      type: String,
      default: ""
    },
    maxCap: {
      type: String,
      default: ""
    },
    createdAddress: {
      type: String,
      default: ""
    },
    smartContract: {
      type: String,
      default: ""
    },
    expertVotes: {
      type: Number,
      default: 0
    },
    minExpertVotes: {
      type: Number,
      default: 0
    },
    hasEscrow: {
      type: Boolean,
      default: false
    },
    terms: {
      type: String,
      default: ""
    },
    assetType: {
      type: String,
      default: ""
    },
    profitability: {
      type: String,
      default: ""
    },
    currentCap: {
      type: String,
      default: ""
    },
    progressProcent: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    projectType: {
      type: String,
      default: ""
    },
    rawStart: {
      type: String,
      default: ""
    },
    rawEnd: {
      type: String,
      default: ""
    },
    allowedForUsers: {
      type: String,
      default: undefined
    },
    tokenBase: {
      type: String,
      default: () => {}
    },
    tokenQuote: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      investCount: null
    };
  },
  computed: {
    ...mapGetters({
      language: "language",
      profile: "profile/profile"
    }),

    minQuote() {
      const price = BigNumber(this.priceBase).dividedBy(BigNumber(10).pow(this.mnemonicDecimals));
      const value = BigNumber(this.minCount).multipliedBy(price);
      return value.isNaN() ? "0" : value.toString(10);
    },

    tokenIcon() {
      return this.tokenQuote?.logoMain ?? "/svg/companies/s_logo.svg";
    },

    logoIcon() {
      return this.tokenBase?.blockChain ? this.tokenBase?.blockChain.logotype :
        (this.tokenBase?.logoMain ?? this.tokenBase?.mainImage?.name) ?? "/svg/companies/s_logo.svg";
    },

    softStyle() {
      const percent = this.getPercentage(this.minCap, this.maxCap);

      let stype = {
        "margin-left": (percent > 70 ? 70 : percent) + "%",
        "text-align": percent > 70 ? "end" : "start"
      };

      if (percent <= 70) {
        stype["border-left"] = "2px rgba(40, 229, 147, 0.2509803922) solid";
        stype["padding-left"] = "5px";
      }
      else {
        stype["border-right"] = "2px rgba(40, 229, 147, 0.2509803922) solid";
        stype["padding-right"] = "5px";
        stype["margin-right"] = "5px";
      }

      return stype;
    },

    infoStyle() {
      const percent = this.getPercentage(this.minCap, this.maxCap);
      if (percent > 70) {
        return {
          "justify-content": "end"
        };
      }

      return {

      };
    },

    amountStyle() {
      const percent = this.getPercentage(this.currentCap, this.maxCap);

      let stype = {
        "margin-left": (percent > 85 ? 0 : percent) + "%",
        "text-align": percent > 85 ? "end" : "start"
      };

      if (percent > 85) {
        stype.width = "100%";
      }

      return stype;
    },

    timeText() {
      return this.status === "Waiting" ? this.$t("auCapitals.investBlock.start") :
        this.$t("auCapitals.investBlock.end");
    },

    timeDate() {
      const diff = moment(this.status === "Waiting" ? this.rawStart : this.rawEnd).diff(moment(), "seconds");
      if (diff < 0) {
        return this.$t("auCapitals.investBlock.closed");
      }
      if (diff <= (24 * 60 * 60)) {
        const hour = Math.floor(diff / (60 * 60));
        const min = Math.floor(diff / 60);
        if (hour > 0) {
          return hour + " " + this.$t("auCapitals.investBlock.hour");
        }
        return min + " " + this.$t("auCapitals.investBlock.min");
      }
      const days = Math.floor(diff / (24 * 60 * 60));
      return days + " " + this.$t("auCapitals.investBlock.day");
    },

    investValue() {
      const delta = BigNumber(this.maxCap).minus(this.currentCap).dividedBy(BigNumber(10).pow(this.mnemonicDecimals));
      if (delta.lte(100)) {
        return [];
      }
      if (delta.lte(200)) {
        return ["50", "100", delta.toString(10)];
      }
      if (delta.lte(10000)) {
        return ["100",
          delta.dividedBy(200).integerValue(BigNumber.ROUND_DOWN).multipliedBy(100).toString(10),
          delta.toString(10)];
      }
      return ["100", "5000", "10000"];
    },

    isAllowed() {
      if (this.isIssuer) {
        return false;
      }
      if (!this.allowedForUsers) {
        return true;
      }
      return _.some(this.profile.roles, role => role == this.allowedForUsers);
    },

    isIssuer() {
      if (this.$auth.user()) {
        return this.$auth.user().type == "issuer";
      }
      return false;
    },

    showInvestCountBlock() {
      return this.investValue.length > 0 && this.status === "Open";
    },

    isVoting() {
      return this.projectType === "voting";
    },

    vote() {
      return this.isVoting ? "Vote" : "";
    },

    goToContractScanner() {
      return `${process.env.VUE_APP_SCAN_BASE_URL}contract/${this.smartContract}`;
    },

    scannerTokenLink() {
      return `${process.env.VUE_APP_SCAN_BASE_URL}token/${this.createdAddress}`;
    }
  },
  methods: {
    shortAddressLowercase(address) {
      return this.$filters.shortAddress(address).toLowerCase();
    },

    goToAdrScanner() {
      window.open(this.goToContractScanner, "_blank");
    },

    goToTokenScanner() {
      window.open(this.scannerTokenLink, "_blank");
    },

    getPercentage(dividend, divider) {
      const percentage = BigNumber(dividend).dividedBy(divider).multipliedBy(100);
      if (percentage.isGreaterThanOrEqualTo(100)) {
        return 100;
      }

      return Math.round(percentage.toNumber());
    }
  }
};
</script>

<style scoped lang="scss">
.ib-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 25px;
  line-height: 1.25em;
}
.ib-price {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;

  a {
    text-decoration: underline;
    color: var(--text-color);
  }

  .blockchain-img {
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  .token-icon {
    width: 30px;
    height: 30px;
  }

  .value {
    position: relative;
    right: -2px;
  }

  .icon-up {
    position: relative;
    top: -2px;
  }

  .line {
    display: flex;
    align-items: center;
    gap: 3px;
  }
}
.ib-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;

  .record {
    display: flex;
    align-items: end;
    gap: 10px;

   .green {
     color: #279063;
   }
   .link {
     cursor: pointer
   }

    .sep {
      height: 2px;
      flex: 1;
      border-bottom: 1px dashed var(--color-50);
      margin-bottom: 4px;
      opacity: 0.5;
    }

    .sep-green {
      border-bottom: 1px dashed #279063;
    }
  }
}
.ib-progress {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 25px;
  .under-info {
    display: flex;
    justify-content: space-between;
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    .label {
      font-size: 12px;
      color: var(--color-50);
    }
    .value {
      font-size: 15px;
      color: var(--text-color);
    }
  }
  .hard {
    width: 100%;
    align-items: end;
  }
}
.ib-popular {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 15px;
}
.ib-invest {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-size: 22px;
  }
  img {
    width: 30px;
    height: 30px;
    filter: brightness(0) invert(1);
  }
}
.grey {
  color: var(--color-50);
}
</style>